<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        Aktuellen Bestand von {{s.bottles}} Flaschen ausbuchen
      </h1>
    </div>
    <div class="card-content">
      <label
        >Bitte geben Sie aus Gründen der Nachvollziehbarkeit an, warum der Wein
        aus dem Lager ausgebucht wird.</label
      >
      <b-input v-model="reasonForRemove" placeholder="Bitte angeben"> </b-input>
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
        <b-button
          v-if="readyToSave"
          type="is-danger"
          icon-left="cancel"
          @click="removeFromStock2()"
          >Jetzt ausbuchen</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemoveFromStock",
  props: ["s"],
  data: function() {
    return {
      reasonForRemove: "",
      submitted: false,
      error: false
    }
  },
  computed: {
    readyToSave: function() {
      if (this.reasonForRemove.length > 3) {
        return true
      }
      return false
    }
  },
  created: function() {},
  methods: {
    async removeFromStock2() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/stocks/removefromstock", {
          s: t.s,
          reasonForRemove: t.reasonForRemove
        })
        .then(function(resp) {
          t.isLoading = false
          //t.$router.push({ path: "/consignments/view/"+resp.data.data });
          
          t.$parent.$parent.stockArticle = resp.data.data
          t.$parent.$parent.parseImages()
          t.$parent.close()
          window.scrollTo(0, 0)

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    }
  }
}
</script>

<style></style>
