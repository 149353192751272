<template>
  <div class="">
    <div id="wine">
      <h2 class="panel-heading is-4"><b-icon icon="bottles"></b-icon> Wein</h2>
      
      <div class="panel-block">
      
        <wine-field
          v-if="stockArticle.id"
          :w="stockArticle"
          :wineIndex="0"
          :inputmode="false"
          :priceChangeAllowed="true"
        />
        </div>

        
    </div>
        

    <div id="cinvoices">
      <h2 class="panel-heading is-4">
        <b-icon icon="document-check"></b-icon>Rechnungen
      </h2>
      <b-table v-if="invoice != null && typeof invoice.invoice_id"
        :data="invoices"
        :striped="true"
        :hoverable="true"
        :mobile-cards="false"
        :row-cell="(row, index) => getStatusColor(row.status_code)"
        class="mb-5"
      >
        <b-table-column
          v-slot="props"
          field="Invoice.id"
          cell-class="status"
          numeric
          label="ReNr"
        >
          {{ props.row.type == "proforma" ? "PF" : "RE" }}
          <router-link :to="`/invoices/view/${props.row.id}`">{{
            $utilities.padLeftWithNull(props.row.doc_id)
          }}</router-link>
        </b-table-column>

        <b-table-column v-slot="props" field="created_at" numeric label="Datum">
          {{ $utilities.formatUnixtimeToDmYHi(props.row.created_at, false) }}
        </b-table-column>

        <b-table-column v-slot="props" field="Invoice.customer_id" label="Kunde">
          #{{ $utilities.padLeftWithNull(props.row.customer_id) }},
          {{ props.row.name }}
        </b-table-column>

        <b-table-column v-slot="props" field="Invoice.total" numeric label="Fl.">
          {{ props.row.bottles }}
        </b-table-column>

        <b-table-column v-slot="props" field="Invoice.total" numeric label="Preis/Fl.">
          {{ nf.format(props.row.price) }}€
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="Invoice.vat_absolute"
          numeric
          label="Gewinn"
        >
          {{ nf.format(props.row.profit) }}€
        </b-table-column>

        <b-table-column v-slot="props" class="nobreak" label="Status">
          <b-tag type="is-danger" size="is-small" v-if="props.row.cancelled >= 1"
            >storn.</b-tag
          >
          <b-icon
            v-if="props.row.cancelled == 0"
            icon="dollar"
            :class="{
              green: props.row.paid > 1,
              grey: props.row.paid == 0 || props.row.paid == null,
            }"
            title="bezahlt"
            style="padding-right: 5px"
          >
          </b-icon>

          <b-icon
            v-if="
              props.row.sum_wines >= 1 &&
              props.row.cancelled == 0 &&
              props.row.type == 'invoice'
            "
            icon="delivery"
            :class="{
              green: props.row.delivered > 1,
              grey: props.row.delivered == 0 || props.row.delivered == null,
            }"
            title="versandt"
          ></b-icon>
        </b-table-column>

        <b-table-column v-slot="props" class="iconlink" header-class="iconlink">
          <router-link :to="`/invoices/view/${props.row.id}`">
            <b-icon icon="find_in_page"></b-icon>
          </router-link>
        </b-table-column>
      </b-table>
      <div v-if="invoice == null" class="pb-3">Keine Rechnungen zu diesem Lagerposten.</div>
    </div>





    <div id="customer">
      <h2 class="panel-heading is-4">
        <b-icon icon="contact_mail"></b-icon> Lieferant und Lagerstatus
      </h2>
      <div class="columns">
        <div class="column is-8 pt-5">
          <customer-field
            v-if="stockArticle.id"
            :customer="stockArticle.customer"
            :changeLink="false"
            class="columns"
          />
        </div>
        <div class="column is-2 pt-4">
          <label>Lagerort</label>
          <div>{{ stockArticle.stockplace }}</div>

          <label>Lager-ID</label>
          <div>{{ $utilities.padLeftWithNull(stockArticle.id) }}</div>

          <label>Im Lager seit</label>
          <div>{{ stockArticle.instockfordays }}</div>
        </div>
        <div class="column is-2 pt-4">
          <label>Flaschen-IDs</label>
          <div>{{ getBottleIds() }}</div>
        </div>
      </div>
    </div>


    <div id="history">
      <h2 class="panel-heading is-4"><b-icon icon="history"></b-icon> History</h2>
      <history
        v-if="stockArticle.id"
        :history="stockArticle.history"
        class="card-content"
      />
    </div>
    <div class="buttons">
      <router-link
        to="/stocks/index#app"
        class="button"
        style="margin-right: 10px"
        custom
        v-slot="{ navigate }"
      >
        <button class="button" @click="navigate" @keypress.enter="navigate" role="link">
          Zurück zur Übersicht
        </button></router-link
      >

      <b-button
        v-if="stockArticle.status_code >= 1"
        @click.prevent="removeFromStock()"
        class="button is-danger"
        icon-left="cancel"
        >Bestand ausbuchen</b-button
      >
    </div>
  </div>
</template>

<script>
import CustomerField from "../_helpers/customerfield.vue";
import WineField from "../_helpers/winefield.vue";
import History from "../_helpers/history.vue";
import RemoveFromStock from "../_helpers/removefromstock.vue";
import merge from "lodash/merge";
export default {
  name: "stockView",
  components: {
    CustomerField,
    WineField,
    History,
  },

  data: function () {
    return {
      isLoading: true,
      specialoffer:0,
      stockArticle: {},
      noticeFormVisible: false,
      notice: { reminderdate: null, notice: "" },
      submitted: false,
      error: false,
      invoices: [],
      invoices: [],
      nf: Intl.NumberFormat(),
    };
  },
  created: function () {

    this.stockView(this.$route.params.id);
  },

  methods: {
    stockView: function (id) {
      var t = this;
      t.$http({
        url: "/stocks/view/" + id,
        method: "get",
      })
        .then(function (resp) {
          t.stockArticle = resp.data.data;
          t.parseImages();
          t.isLoading = false;
          t.stockArticle.instockfordays = t.calcLength();
          var profit = 0;
          if (
            t.stockArticle.invoice_id != null &&
            typeof t.stockArticle.invoice != null
          ) {
            profit =
              t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.price /
              t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.bottles;
            profit = profit - t.stockArticle.price_ek;
            profit =
              profit *
              t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.bottles;

            if (t.stockArticle.from_private == 1) {
              profit = profit / (1 + t.$store.getters.config.vat / 100);
            }
            profit = Math.round(profit * 100) / 100;
            t.invoices = push(
              merge(t.stockArticle.invoice, {
                bottles:
                  t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.bottles,
                price:
                  t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.price /
                  t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.bottles,
                profit: profit,
              })
            );
          } else if (
            t.stockArticle.invoiceArticleStockArticle != null &&
            t.stockArticle.invoiceArticleStockArticle.length >= 1
          ) {
            for (var i = 0; i < t.stockArticle.invoiceArticleStockArticle.length; i++) {
              if (
                t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle != null &&
                t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.invoice !=
                  null
              ) {
                profit =
                  t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.price /
                  t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.bottles;
                profit = profit - t.stockArticle.price_ek;
                profit = profit * t.stockArticle.invoiceArticleStockArticle[i].bottles;
                
                if (t.stockArticle.from_private == 1) {
                  profit = profit / (1 + t.$store.getters.config.vat / 100);
                }
                profit = Math.round(profit * 100) / 100;
                t.invoices.push(
                  merge(
                    t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle.invoice,
                    {
                      bottles: t.stockArticle.invoiceArticleStockArticle[i].bottles,
                      price:
                        t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle
                          .price /
                        t.stockArticle.invoiceArticleStockArticle[i].invoiceArticle
                          .bottles,
                      profit: profit,
                    }
                  )
                );
              }
            }
          }
          t.specialoffer = t.stockArticle.specialoffer;
          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
    },
    parseImages: function () {
      var t = this;
      //console.log(typeof t.stockArticle.images)
      if (typeof t.stockArticle.images == "string" && t.stockArticle.images.length > 15) {
        //console.log("parse images 2")
        t.stockArticle.images = JSON.parse(t.stockArticle.images);
      }
    },
    removeFromStock() {
      var t = this;
      t.$buefy.modal.open({
        parent: t,
        component: RemoveFromStock,
        hasModalCard: false,
        width: 960,
        props: { s: this.stockArticle },
      });
    },
    addToNewAuction() {
      this.$router.push("/auctions/lots/addlot/" + this.stockArticle.id);
    },
    calcLength() {
      var time = 0;

      for (var i = 0; i < this.stockArticle.history.length; i++) {
        if (this.stockArticle.history[i].module == "consignments") {
          time = this.stockArticle.history[i].datum;
        }
      }

      if (time == 0) {
        return "n/a";
      } else {
        var diff = Math.floor((Math.floor(Date.now() / 1000) - time) / 86400);
      }
      if (diff == 1) {
        return "1 Tag";
      }
      return diff + " Tagen";
    },
    getBottleIds() {
      var bottles = this.$utilities.padLeftWithNull(this.stockArticle.id);
      for (var i = 1; i <= this.stockArticle.bottles; i++) {
        if (i == 1) {
          bottles = bottles + "-" + this.$utilities.padLeftWithNull(i, 2);
        } else {
          bottles = bottles + "/" + this.$utilities.padLeftWithNull(i, 2);
        }
      }
      return bottles;
    },
    updateSpecialofferStatus () {
       //console.log('savePrice', this.w.price_vk);
       var t = this
      t.$http
        .post("/stocks/update-specialoffer-status", {
          id: t.stockArticle.id,
          specialoffer: t.specialoffer
        })
        .then(function(resp) {
         
          return true
        })
        .catch(function(error) {
          
          alert('Es ist ein unbekannter Fehler aufgetreten');
          
          return false
        })
    },

    getReservationStatus (invoice_id, released)
    {
      var t = this
      
      if (released == 1)
      {
        return 'Reservierung aufgehoben, siehe History';
      }
      else if (invoice_id != null && invoice_id != 0 && invoice_id > 100)
      {
        return 'Abgerechnet mit Rechnung #'+t.$utilities.padLeftWithNull(invoice_id);
      }
      else
      {
        return 'Aktuell reserviert';
      }
    
    },
    cancelReservation (id, order_id) {
      
    }
  },
};
</script>
<style>
div#status,
div#customer {
  margin-bottom: 30px;
}
</style>
